<template>
  <div v-loading="true" class="d-flex align-items-center justify-content-center" style="height: 100vh;">
    <h2>Vous allez être redirigé vers Mayday</h2>
  </div>
</template>

<script>
export default {
  name: 'Redirect',
  mounted() {
    const { url, token, accessExpirationDate } = this.$route.query;
    const decodedUrl = atob(url);

    if (this.$route.query.code) {
      window.location.href =
        decodedUrl +
        `/redirect?token=${token}&accessExpirationDate=${accessExpirationDate}&code=${this.$route.query.code}`;
      return;
    } else {
      let webExtStore = JSON.parse(localStorage.getItem('web-ext'));
      if (webExtStore) {
        webExtStore.webExt.accessToken = token;
        webExtStore.webExt.accessExpirationDate = accessExpirationDate;

        localStorage.setItem('web-ext', JSON.stringify(webExtStore));
      }

      window.location.href =
        decodedUrl +
        `/redirect?token=${token}&accessExpirationDate=${accessExpirationDate}`;
    }
  },
};
</script>

<style></style>
